@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

html {
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    font-size: .875em
}

@media (min-width: 768px) {
    html {
        font-size: .75em
    }
}

#primary {
    margin-top: 5em
}

.survey-page {
    background-color: #fff;
    color: #000
}

@media (min-width: 768px) {
    .survey-page {
        padding: 16px;
        background-color: #fff
    }
}

.survey-body {
    padding: 1em 14px 0;
    background-color: #fff;
    position: relative;
    border: 0 none transparent;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px
}

@media (min-width: 768px) {
    .survey-body {
        padding-left: 18px;
        padding-right: 18px
    }
}

.survey-header {
    margin-bottom: 1.5em;
    min-height: 2.5em
}

.intro {
    margin-top: 0;
    position: relative;
    z-index: 5
}

.description-desktop {
    display: initial
}

.description-mobile {
    display: none
}

.description-table {
    width: 70%;
    margin: auto;
    display: table
}

.description-head-row {
    display: table-header-group;
    width: 100%
}

.description-head {
    display: table-cell;
    text-align: center;
    font-size: .9em;
    width: 25%;
    padding: 5px
}

.description-body {
    display: table-row-group;
    width: 100%
}

.description-row {
    display: table-row
}

.description-cell {
    display: table-cell;
    width: 25%;
    padding: 10px
}

.info {
    width: 70%;
    font-size: .9em;
    margin-top: 5px;
    margin: auto;
    text-align: left
}

@media only screen and (max-width: 750px) {
    .info {
        width: 100%
    }

    .description-desktop {
        display: none
    }

    .description-mobile {
        display: initial;
        width: 100%
    }

    .description-cell {
        width: 50%;
        padding: 10px
    }

    .intro {
        width: 100%
    }

    .description-table {
        width: 100%
    }
}

a {
    color: #916daf
}

a:hover {
    color: #cbe9e9
}

.input {
    margin: 0
}

input[type=text], textarea {
    border: 1px solid #73c4c4;
    color: #000;
    font-size: 1rem;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px
}

input[type=text]:focus, textarea:focus {
    background: #cbe9e9;
    border: 1px solid #73c4c4
}

.answers {
    margin-top: 10px
}

textarea {
    background: #cbe9e9
}

input[type=text] {
    padding: .25em .5em
}

.noRows.noCols input[type=text] {
    padding: .5em .75em
}

.number .answers, .textarea .answers {
    text-align: center
}

.textarea {
    padding: .75em .5em;
    width: 95%;
    cursor: text
}

.oe-inline, .oe-left {
    margin-left: .5em;
    background: #fff;
    width: 95%
}

.oe-right {
    margin-right: .5em
}

.clickableCell:hover {
    background-color: #cbe9e9
}

.text > .answers-list {
    display: inline-block;
    text-align: center
}

.logo {
    margin-bottom: 1em
}

.logo-left {
    text-align: left
}

.logo-middle {
    text-align: center
}

.logo-right {
    text-align: right
}



.survey-progress-bar {
    width: 100%;
    margin-bottom: 0;
    text-align: center
}

@media (min-width: 768px) {
    .survey-progress-bar {
        text-align: left;
        float: left;
        width: 100%
    }

    .logo-middle + .survey-progress-bar, .logo-right + .survey-progress-bar {
        position: absolute;
        top: 1em;
        left: 2%;
        float: none
    }

    .survey-progress-bar:after {
        content: " ";
        display: table;
        clear: both
    }
}

.progress-bottom {
    margin-top: 1em;
    margin-bottom: 0
}

.progress-box-outer, .progress-text {
    display: inline-block;
    vertical-align: top
}

.progress-box-outer {
    background-color: transparent;
    border: 5px solid #c7abdf;
    border-radius: 8px;
    width: 100%;
    text-align: right;
    height: 2.5em;
    display: inline-block;
    padding: 3px
}

.progress-box-completed {
    background-color: #916daf;
    width: 0;
    height: 100%;
    display: block;
    border: 1px solid #916daf;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.progress-text {
    color: transparent;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    font-size: 1rem;
    line-height: 1em;
    padding-left: .5em;
    display: none
}

.comment {
    background: 0 0;
    margin-top: 1em;
    margin-bottom: 2.5em;
    padding: 0 0
}

@media (min-width: 768px) {
    .comment {
        padding: 0 0
    }
}

.comment-text {
    color: #000;
    font-size: 1.3rem;
    line-height: normal;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    text-align: center
}

.question {
    background: 0 0;
    margin-top: 1em;
    margin-bottom: 2.5em;
    clear: both;
    border: 0 none transparent;
    padding: 0 0
}

@media (min-width: 768px) {
    .question {
        padding: 0 0
    }
}

.question-text {
    color: #000;
    font-size: 1.3rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    margin-top: 1em;
    margin-bottom: .333em;
    line-height: normal;
    text-align: center;
    width: 100%;
    margin: auto
}

.instruction-text {
    color: #696a69;
    font-size: 1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    margin-bottom: 1.25em;
    font-style: italic;
    text-align: center;
    margin-bottom: 25px;
    margin: auto
}

.answers, .fir-select2-dropdown {
    color: #696a69;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif
}

.answers-list {
    margin-left: 1em
}

tr.even {
    background-color: #cbe9e9
}

select {
    background-image: url(//opinionpanel.decipherinc.com/survey/selfserve/53b/190702/selectarrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    border: 3px #73c4c4 solid;
    border-radius: 7px;
    background-color: #cbe9e9;
    background-size: auto 100%;
    color: #696a69;
    width: 90%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 40px;
    font-size: 1.1rem;
    text-align: left;
    display: block;
    margin: 0 auto !important
}

@media (min-width: 768px) {
    select {
        text-align: center
    }
}

.sq-cardsort-bucket {
    background-color: #916daf !important
}

.sq-cardsort-bucket:hover {
    background-color: #73c4c4 !important
}

.carousel-image {
    max-width: 90%;
    max-height: 350px
}

@media (max-width: 768px) {
    .carousel-image {
        width: 100%
    }
}

.sq-cardsort-card-legend .sq-cardsort-card-legend-left {
    margin-top: 0 !important
}

.sq-atm1d-widget {
    text-align: center
}

.cell-text {
    padding: 0 0 0 .4em
}

.checkbox .cell-text, .radio .cell-text {
    padding: 0 0 0 .25em
}

.cell-legend-left .cell-text {
    padding: 0 .6em 0 0
}

.checkbox .cell-legend-left .cell-text, .radio .cell-legend-left .cell-text {
    padding: 0 .6em 0 0
}

.cell-legend-above .cell-text {
    padding: 0 0 .25em 1px
}

.checkbox .cell-legend-above .cell-text, .radio .cell-legend-above .cell-text {
    padding: 0 0 .25em 1px
}

.legend {
    font-weight: 400;
    padding: .5em
}

.element {
    padding: .4em .4em .4em 0
}

.indent-1 {
    padding-left: 1.167em
}

.indent-2 {
    padding-left: 1.75em
}

.indent-3 {
    padding-left: 2.333em
}

.legend-level-1 {
    font-size: 1em
}

.legend-level-2 {
    font-size: 1.23em
}

.legend-level-3 {
    font-size: 1.3em
}

.answers-list, .row-elements {
    background: #fff
}

.col-legend {
    background-color: none
}

.row-legend-group {
    font-size: 1.2em;
    background-color: #fff
}

.col-legend-group {
    background-color: none
}

.row-elements + .row-elements, .row-elements + .row-group {
    margin-top: 2em
}

.zeroHeight + .row-elements, .zeroHeight + .row-group {
    margin-top: 0
}

.grid-table-mode .legend {
    padding: .4em .5em;
    font-size: 1em
}

.grid-table-mode input[type=text], .grid-table-mode textarea {
    padding: .25em .5em
}

.grid-table-mode .nonempty {
    border: 1px solid none
}

.grid-table-mode .row-legend-group {
    font-size: 1.2em
}

.grid-table-mode .element {
    padding: .4em
}

.grid-table-mode .element:active, .grid-table-mode .element:focus, .grid-table-mode .element:hover {
    background-color: #c7abdf
}

.grid-table-mode .row-elements + .row-elements, .grid-table-mode .row-elements + .row-group {
    margin-top: 0
}

.survey-buttons {
    margin: 1em 0 0;
    text-align: center;
    font-weight: 700;
    width: 100%;
    max-width: 100%
}

@media (min-width: 768px) {
    .survey-buttons {
        text-align: right
    }
}

.button {
    background-color: #fff;
    color: #662f94;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    border: 1px solid transparent;
    box-shadow: 1px 1px 2px #fff;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, .8);
    -webkit-box-shadow: 1px 1px 2px rgba(255, 255, 255, .8);
    -moz-box-shadow: 1px 1px 2px rgba(255, 255, 255, .8);
    -webkit-appearance: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    cursor: pointer;
    padding: .7em 1em;
    margin: .111em
}

.button:focus, .button:hover {
    background-color: #c7abdf;
    border-color: transparent;
    color: #662f94
}

.button:active {
    background-color: #c0a0db
}

.button.continue {
    background-image: url(//opinionpanel.decipherinc.com/survey/selfserve/53b/190702/arrowright.png);
    background-repeat: no-repeat;
    background-position: right center
}

.button .finish, .button.randomize {
    background-color: #fff;
    color: #662f94;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    border: 1px solid transparent;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

.button.back {
    background-image: url(//opinionpanel.decipherinc.com/survey/selfserve/53b/190702/arrowleft.png);
    background-repeat: no-repeat;
    background-position: left center
}

.button:focus, .button:hover {
    background-color: #c7abdf;
    border-color: transparent;
    color: #662f94
}

.button:active {
    background-color: #c0a0db
}

.next_top {
    position: absolute;
    top: 4em;
    right: 0;
    margin: 0 15px
}

.back_top {
    position: absolute;
    top: 4em;
    left: 0;
    margin: 0 15px
}

.next_bottom {
    float: right
}

.back_bottom {
    float: left
}

.finish {
    background-color: #662f94;
    color: #fff;
    font-size: 1.3rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    font-weight: 700;
    padding: 5px;
    border: 1px solid #662f94;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 50px
}

.finish:hover {
    background-color: #c7abdf;
    border-color: #c7abdf
}

.magic-suggest > .survey-answers {
    display: none
}

.ms-ctn {
    border: 2px solid #cbe9e9;
    border-radius: 3px;
    padding-right: 25px
}

.ms-ctn-focus {
    box-shadow: inset 0 1px 1px #f5e9ff, 0 0 8px #cbe9e9;
    webkit-box-shadow: inset 0 1px 1px #f5e9ff, 0 0 8px #cbe9e9
}

.ms-ctn .ms-trigger {
    border-left: 2px solid #cbe9e9
}

.ms-ctn .ms-trigger:hover {
    background-color: #cbe9e9;
    right: 0;
    width: 25px;
    border-radius: 0
}

.ms-ctn .ms-trigger:hover .ms-trigger-ico {
    background-position: 0 0
}

.ms-res-ctn .ms-res-item-active {
    background-color: #cbe9e9
}

.selectbox {
    background-color: #f1f9f9;
    border-color: #cbe9e9;
    color: #696a69;
    font-size: 1.1rem
}

#custom-ctn {
    width: 700px;
    margin-top: 2%
}

@media only screen and (max-width: 650px) {
    #custom-ctn {
        width: 100%
    }
}

.ms-helper {
    font-size: 1rem
}

.exit-message-header {
    display: none;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    margin-bottom: .75em
}

.exit-message-text {
    font-size: 1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif
}

.final-page-header {
    margin-top: 50px;
    font-size: 1.5em;
    text-align: center;
    line-height: 1.15em
}

.screenout {
    font-size: 1.3em;
    text-align: center;
    line-height: 1.15em
}

.survey-error {
    padding: 1em;
    margin-top: 5em;
    background-color: #ba0000;
    color: #fff;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px
}

.question-error {
    margin: .5em 0;
    background-color: transparent;
    color: #ba0000;
    font-size: 1.2em;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px
}

.answers-list .element.hasError, .cell.hasError, .colLegendErr .oe, .element.hasError:hover, .rowLegendErr .oe {
    background-color: #ffeded
}

.survey-info, .survey-warning {
    font-weight: 700
}

.autosave-restart, .survey-info, .survey-warning {
    padding: .714em;
    margin-bottom: 1em;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px
}

.survey-info {
    background-color: #cf6;
    color: #000;
    font-size: 1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif
}

.survey-warning {
    background-color: pink;
    color: #000;
    font-size: 1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif
}

.autosave-restart {
    background-color: #cf6;
    color: #000;
    font-size: 1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    text-align: center;
    text-decoration: none
}

.autosave-restart:hover {
    background: #bf3;
    color: #000
}

.footer {
    color: #fff;
    font-size: 1.1rem;
    font-family: "Trebuchet MS", Arial, Helvetica, Verdana, "Verdana Ref", sans serif;
    text-align: center;
    margin-left: 2em
}

@media (min-width: 800px) {
    .footer {
        margin-left: 5em
    }
}

.footer a {
    color: #fff
}

.footer a:active, .footer a:focus, .footer a:hover {
    color: #fff
}

.footer-button {
    float: left;
    padding: .5em;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    background-color: #c7abdf;
    border-radius: 0 0 3px 3px;
    text-shadow: 0 2px 2px #696a69;
    margin-right: 3px;
    text-decoration: none
}

.hide {
    visibility: hidden
}

.rowgap {
    margin-top: 30px
}

#myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: .3s
}

#myImg:hover {
    opacity: .7
}

.modalImg {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0, 0, 0, .9)
}

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px
}

#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px
}

#caption, .modal-content {
    animation-name: zoom;
    animation-duration: .6s
}

@keyframes zoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: 700;
    transition: .3s
}

.close:focus, .close:hover {
    color: #bbb;
    text-decoration: none;
    cursor: pointer
}

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%
    }
}