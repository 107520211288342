body {
    font-size: 16px;
}

.container {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

nav {
    font-size: 24px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ul:last-child {
        font-size: 16px;
    }
    margin-bottom: 10px;
}

.summaryHeader{
    margin: 30px 0px 30px 0px;
}

.material-icons, .material-icons-outlined{
    vertical-align: middle;
    padding-right: 7px;
}

.summaryContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom:30px;
    div {
        display: flex;
        flex-direction: column;
        border: 3px solid #ccc;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;

        span {
            text-align: center;
            &:nth-child(1) {
                color: #333;
            }
            &:nth-child(2) {
                margin-top: 10px;
                font-weight: bold;
            }
        }
    }
}